import React from "react"
import { graphql,Link } from "gatsby"
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Card from 'react-bootstrap/Card'

export const data = graphql`
query {
  allGuideDataset {
    edges {
        node {
          Title
          FeaturedImageURL
          Description
          DownloadURL
          SlugURL
      }
    }
  }
}
`

export const seo = {
  title: 'Guides | Crampete',
  description: 'Download Crampete\'s comprehensive PDF guides on the most important career building topics. It\'s free, so download to your heart\'s content. Get instant access!',
  keywords: 'Crampete, Guides, PDF',
  image: '',
  url: 'https://www.crampete.com/guides',
  author: 'Crampete'
}

const Guide = ({ data }) => {
  return (
    <Layout title={seo.title} description={seo.description} image={seo.image} keywords={seo.keywords} url={seo.url} author={seo.author} >
    <Jumbotron className="bg-lightblue">
      <div className="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
          <h2>Crampete Guide</h2>
          <p className="mt-4">
          Here's what our students have to say about being a part of the Crampete family and learning skills that empower them!
          </p>
        </div>
      </div>
    </Jumbotron>
        <div className="container">
          <div className="row">
              {data.allGuideDataset.edges.map(({ node }) => {
                  return (
                      <div className="col-md-6 col-lg-3 col-xl-3" style={{ marginBottom: '20px' }}>
                        <Card className="cardShadow">
                          <Card.Img variant="top" src={node.FeaturedImageURL} />
                          <Card.Body>
                            <Card.Title>{node.Title}</Card.Title>
                            <Card.Text>
                              <div
            	                  dangerouslySetInnerHTML={{ __html: node.Description }}
            	                />
                            </Card.Text>
                          </Card.Body>
                          <Card.Body>
                            <Link className="btn btn-primary" to={"/guide/"+node.SlugURL + "/"}>Read more</Link>
                          </Card.Body>
                        </Card>
                      </div>
                );
              })}
          </div>
          </div>
    </Layout>
  )
}

export default Guide
